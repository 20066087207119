// Polyfill imports have to be on first line
import "react-app-polyfill/stable";
import React from "react";
// import { ComponentName } from "careibu-constants/component/ComponentName.js";
import { createRoot } from "react-dom/client";
import {
  appHostNames,
  AppName,
} from "./utils/appHostnames";

function importAppRoot() {
  // Development
  if (import.meta.env.DEV) {
    return importAppByName(import.meta.env.REACT_APP_COMPONENT_NAME);
  }

  // PROD CONFIG
  return importAppRootByHostname();
}

const importAppRootByHostname = () => {
  const isStudentApp = appHostNames.student.includes(window.location.hostname);
  const isClientApp = appHostNames.client.includes(window.location.hostname);
  const isSeniorApp = appHostNames.senior.includes(window.location.hostname);

  const appName: AppName | null = isSeniorApp
    ? "senior"
    : isClientApp
    ? "client"
    : isStudentApp ? "student" : null;

  return importAppByName(appName);
};

const importAppByName = (name: AppName | null) => {
  switch(name) {
    case "student":
      return import("./app/student/App");
    case "client":
      return import("./app/client/App");
    case "senior":
      return import("./app/senior/App");
    default:
      throw new Error(
        "Unknown App"
      );
  }
}



importAppRoot().then(({ App }) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<App />);
});
